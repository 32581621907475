// Additional polyfills
/* eslint-disable */
import "custom-event-polyfill";
import "url-polyfill";

import Vue from "vue";
import App from "./App";
import store from "./store"
import router from "./router";

import BootstrapVue from "bootstrap-vue";

import globals from "./globals";
import Popper from "popper.js";

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// SYNCFUSION
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "MTI3ODY3MUAzMjMwMmUzNDJlMzBiOXY3ZG1rekJ2QWs3N1Y2Qm9SN00zZ0p2QThIQzlSQS9JWE5sVFJ6N2I4PQ==;Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSn5bdkFjX3xacXFSQw==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZjUX9acHJWQmFbVEE="
);

// SWEETALERT
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

// Global RTL flag
Vue.mixin({
  data: globals
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
