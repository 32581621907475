<template>
  <div id="layout1" class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar v-if="$route.path !== '/login'" :banderas="banderas" :date="date" :dateEng="dateEng" v-on:lang="lang"/>

      <div class="layout-container">
        <app-layout-sidenav v-if="$route.path !== '/login'" :idioma="idioma" :key="componentKey" />

        <div class="layout-content">
          <div v-if="$route.path == '/login'" class="router-transitions">
            <router-view v-on:banderas="flags" />
          </div>
          <div
            v-else
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <router-view
              v-bind="obtenerFecha()"
              :date="date"
              :dateSimpleSpanish="dateSimpleSpanish"
              :dateSimpleEnglish="dateSimpleEnglish"
              :day="day"
              :diaSemana="diaSemana"
              :day7minus="day7minus"
              :month="month"
              :monthMinus1="monthMinus1"
              :yearMinus1="yearMinus1"
              :year="year"
              :hour="hour"
              :idioma="idioma"
              v-on:usuarioSeleccionado="usuarioSeleccionado"
              :datosUsuario="datosUsuario"
            />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";

export default {
  name: "app-layout-1",
  components: {
    "app-layout-navbar": LayoutNavbar,
    "app-layout-sidenav": LayoutSidenav,
    "app-layout-footer": LayoutFooter
  },
  data() {
    return {
      date: "",
      dateSimpleSpanish: "",
      dateSimpleEnglish: "",
      dateEng: "",
      month: "",
      year: "",
      day: "",
      diaSemana: "",
      day7minus: "",
      yearMinus1: "",
      monthMinus1: "",
      hour: "",
      banderas: [],
      idioma: "",
      datosUsuario: {},
      componentKey: 0
    };
  },
  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },
  beforeDestroy() {
    this.layoutHelpers.destroy();
  },
  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
    flags(datos) {
      this.banderas = datos;
    },
    lang(datos) {
      this.idioma = datos;
    },
    usuarioSeleccionado(datos) {
      console.log(datos);
      this.datosUsuario = datos
    },
    // FECHA
    // Función calculo de fecha actual
    obtenerFecha() {
      const dias = new Array(
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado"
      );
      const days = new Array(
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      );
      const meses = new Array(
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      );
      const months = new Array(
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      );
      const fecha = new Date();
      this.date = `Hoy es ${dias[fecha.getDay()]} , ${fecha.getDate()} de ${
        meses[fecha.getMonth()]
      } de ${fecha.getFullYear()}`;
      this.dateSimpleSpanish = `${fecha.getDate()}/${
        meses[fecha.getMonth()]}/${fecha.getFullYear()}`;
      this.dateEng = `The date is ${days[fecha.getDay()]}, ${
        months[fecha.getMonth()]
      } ${fecha.getDate()}, ${fecha.getFullYear()}`;
      this.dateSimpleEnglish = `${
        months[fecha.getMonth()]
      }/${fecha.getDate()}/${fecha.getFullYear()}`;
      this.month = meses[fecha.getMonth()];
      this.year = fecha.getFullYear();
      this.day = fecha.getDate();
      this.diaSemana = dias[fecha.getDay()];
      this.day7minus = this.day - 7;
      this.yearMinus1 = this.year - 1;
      this.monthMinus1 = meses[fecha.getMonth() - 1];
      this.hour =
        fecha.getHours() +
        ":" +
        ((fecha.getMinutes() < 10 ? "0" : "") + fecha.getMinutes()) +
        ":" +
        ((fecha.getSeconds() < 10 ? "0" : "") + fecha.getSeconds());
    },
  }
};
</script>
<style>
@import url("https://cdn.syncfusion.com/ej2/material.css");
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.1.2/css/bootstrap.min.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
/* texto de las filas de cabecera de las tablas */
.e-stackedheadercelldiv {
  color: black;
  padding: 0 !important;
}
/* nombres de los títulos de las columnas */
.e-grid .e-headercell {
  color: darkblue;
}
/* posicion de las grids de asignación entregas */
#Grid {
  float: left;
}
#DestGrid {
  float: right;
}
/* botones redondos de las toolbar */
#asignar,
#ClickUp,
#ClickDown,
#excelexport,
#BtnAllowFiltering,
#BtnAllowGrouping,
#BtnGuardarConfiguracion,
#BtnRestaurarConfiguracion,
#asignar2,
#ClickUp2,
#ClickDown2,
#excelexport2,
#BtnAllowFiltering2 {
  background-color: #e3165b;
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
  border-radius: 50% !important;
  line-height: 1;
  padding: 0;
}
#asignar .e-arrow-right,
#ClickUp .e-custom-sort,
#ClickDown .e-sort-descending,
#excelexport .e-export-excel,
#BtnAllowFiltering .e-filter-2,
#BtnAllowGrouping .e-restart-at-1,
#BtnGuardarConfiguracion .e-save,
#BtnRestaurarConfiguracion .e-undo-2,
#asignar2 .e-arrow-left,
#ClickUp2 .e-custom-sort,
#ClickDown2 .e-sort-descending,
#excelexport2 .e-export-excel,
#BtnAllowFiltering2 .e-filter-2 {
  margin-top: 0;
  width: auto;
  color: #fff;
}
#asignar:hover,
#ClickUp:hover,
#ClickDown:hover,
#excelexport:hover,
#BtnAllowFiltering:hover,
#BtnAllowGrouping:hover,
#BtnGuardarConfiguracion:hover,
#BtnRestaurarConfiguracion:hover,
#asignar2:hover,
#ClickUp2:hover,
#ClickDown2:hover,
#excelexport2:hover,
#BtnAllowFiltering2:hover {
  background-color: #897bc9;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
/* estilos de subir archivos */
/* estilos del cuadro de envio */
.subir-archivo .control_subir-archivo {
  max-width: 15rem;
  min-width: 249px;
}
.subir-archivo {
  padding-left: 0px !important;
}
.e-upload {
  border: none !important;
}
/* botón */
#downloadExel, .e-btn, .e-css.e-btn {
  background-color: #e3165b;
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
  line-height: 2;
  border-radius: 5px;
  padding: 0.2rem;
}
#downloadExel:hover, .e-btn, .e-css.e-btn:hover {
background-color: #e6326f;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
#departamentos {
  z-index: 100000 !important;
}
.descargarExcel {
  height: 3rem !important;
}
</style>
