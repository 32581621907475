<template>
  <div style="overflow: hidden; z-index:100" v-if="$route.path !== '/login'">
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div
        class="
          container-fluid
          d-flex
          flex-wrap
          justify-content-between
          text-center
          container-p-x
          pb-1
        "
        style="background-image:url('/images/SILICON_logos/0.png'); !important; height: 3rem; font-size: 0.8rem; "
      >
        <div class="pt-1" style="font-size: 0.6rem">
          Distribuido por
          <a href="https://netcomsi.es/" target="_blank">
            <img
              src="/images/SILICON_logos/netcomLogoBlanco.svg"
              weight="35"
              height="35"
              class="ml-1 mr-1 mb-2 mt-1"
            />
          </a>
        </div>
        <div>
          <a href="http://www.anem-logistics.com/" target="_blank" class="footer-link pt-3"
            >Sobre nosotros</a
          >
          <a href="http://www.anem-logistics.com/contacto/" target="_blank" class="footer-link pt-3 ml-4"
            >Contacto</a
          >
          <a href="#" target="_blank" class="footer-link pt-3 ml-4"
            >Privacidad y protección de datos</a
          >
          <a href="#" target="_blank" class="footer-link pt-3 ml-4"
            >Aviso legal</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    }
  }
};
</script>
