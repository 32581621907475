// Trasnfiere sessionStorage entre ventanas o tabs
function sessionStorageTransfer(event) {
  if(!event) { event = window.event; } // Corrección para IE
  if(!event.newValue) return;          // No hay valor, no hacemos nada
  if (event.key == 'getSessionStorage') {
    // Se ha solicitado la información de sessionStorage -> La preparamos para enviar
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
  } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
    // Otra ventana o tab ha preparado los datos de sessionStorage <- Los guardamos en sessionStorage
    let data = JSON.parse(event.newValue);
    for (let key in data) {
      sessionStorage.setItem(key, data[key]);
    }
    localStorage.removeItem('sessionStorage');
  }
}

// Escuchamos los cambios de sessionStorage
if(window.addEventListener) {
  window.addEventListener("storage", sessionStorageTransfer, false);
} else {
  window.attachEvent("onstorage", sessionStorageTransfer);
}


// Solicita a las otras ventanas o tabs la información de sessionStorage, solo hace lanzar el evento
if (!sessionStorage.length) {
  localStorage.setItem('getSessionStorage', 'sessionStorage');
  // Eliminamos los datos puestos por el evento
  localStorage.removeItem('getSessionStorage', 'sessionStorage');
}


export function getFromStorage(key) {
let result = sessionStorage.getItem(key) ?? localStorage.getItem(key);
if(key == 'token' &&
   result != undefined) {
  let parts = result.split('.');
  if(parts.length >= 2) {
    let timestamp = Math.round(Date.now() / 1000);
    let payload = JSON.parse(Buffer.from(parts[1], 'base64'));
    if(payload.exp &&
       payload.exp <= timestamp) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      result = undefined;
    }
  }
}
return result;
}

export function setInStorage(key, value, remember = false) {
  if(remember) {
      localStorage.setItem(key, value);
  } else {
      sessionStorage.setItem(key, value);
  }
}
