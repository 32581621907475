import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Layout1,
      children: [
        {
          path: "login",
          component: () => import("@/components/Login")
        },
        {
          path: "empleados",
          component: () => import("@/components/gestionUsuarios/Empleados")
        },
        {
          path: "ayuntamientos",
          component: () => import("@/components/gestionUsuarios/Ayuntamientos")
        },
        {
          path: "departamentos",
          component: () => import("@/components/gestionUsuarios/Departamentos")
        },
        {
          path: "importar-envios-postales",
          component: () => import("@/components/altaEnvios/AltaEnviosPostales")
        },
        {
          path: "seguimiento-archivos",
          component: () => import("@/components/altaEnvios/SeguimientoArchivos")
        },
        {
          path: "asignacion-entregas",
          component: () => import("@/components/asignacionEntregas/AsignacionEntregas")
        },
        {
          path: "seguimiento-entregas",
          component: () => import("@/components/asignacionEntregas/SeguimientoEntregas")
        },
        {
          path: "Entregas-asignadas",
          name: "EntregasAsignadas",
          component: () => import("@/components/procesoEntregas/EntregasAsignadas")
        },
        {
          path: "proceso-entrega",
          component: () => import("@/components/procesoEntregas/ProcesoEntrega")
        },
        {
          path: "proceso-firma-cliente",
          // necesario para poder enviar params por this.$router.push que entren como prop
          props: true,
          // para enviar params con router.push hay que usar name
          name: "ProcesoFirmaCliente",
          component: () => import("@/components/procesoEntregas/ProcesoFirmaCliente")
        },
        {
          path: "proceso-firma-operario",
          name: "ProcesoFirmaOperario",
          component: () => import("@/components/procesoEntregas/ProcesoFirmaOperario")
        },
        {
          path: "entregas-externas",
          component: () => import("@/components/procesoEntregas/entregasExternas/EntregasExternas")
        },
        {
          path: "anexar-justificante",
          component: () => import("@/components/procesoEntregas/entregasExternas/AnexarJustificante")
        },
        {
          path: "informe-cierre-mes",
          component: () => import("@/components/informes/InformeCierreMes")
        }
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
